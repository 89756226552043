import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }
`

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #626285 94%, transparent) top/3px 3px no-repeat,
    conic-gradient(transparent 30%, #626285);
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 3px), #000 0);
  animation: ${rotate} 0.6s infinite linear;
`
