import React, { useState, useRef, useEffect } from 'react'

import Select, {
  ControlProps,
  MenuListComponentProps,
  OptionProps,
  StylesConfig,
  GroupTypeBase,
  components
} from 'react-select'

import EllipsisIcon from '../../assets/ellipsis.svg'
import theme from '../../theme.json'
import { Ellipsis, HeaderText } from './styles'

export interface CardOptions {
  label: string
  action: () => void
}

interface OptionsProps {
  options: CardOptions[]
  headerText: string
}

type IsMulti = false

const customStyles: StylesConfig<CardOptions, IsMulti> = {
  control: (provided) => ({
    ...provided,
    width: '0',
    height: '0',
    border: 'transparent',
    boxShadow: 'transparent'
  }),
  input: (provided) => ({
    ...provided,
    width: '0',
    height: '0',
    color: 'transparent'
  }),
  placeholder: (provided) => ({
    ...provided,
    width: '0',
    height: '0',
    color: 'transparent'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    width: '0',
    height: '0'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0',
    height: '0'
  }),
  menu: (provided) => ({
    ...provided,
    width: '177px',
    border: `1px solid ${theme.dark}`,
    borderRadius: '10px',
    boxShadow: 'none',
    paddingBottom: '7px',
    paddingTop: '7px',
    top: '20px',
    left: 0
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? theme.dark : theme.grey2,
    backgroundColor: state.isFocused ? theme.grey5 : 'transparent',
    padding: 10,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    cursor: 'pointer'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.dark,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px'
  })
}

const Options: React.FC<OptionsProps> = ({ options, headerText }: OptionsProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const ref = useRef<Select<CardOptions, false, GroupTypeBase<CardOptions>>>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const onChange = (option: CardOptions | null) => {
    if (option) option.action()
  }

  const openMenu = () => {
    setMenuIsOpen(true)
    ref.current?.focus()
  }

  const closeMenu = () => {
    setMenuIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        closeMenu()
      }
    }

    if (menuIsOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [menuIsOpen])

  const ControlComponent = (
    props: ControlProps<CardOptions, false, GroupTypeBase<CardOptions>>
  ) => (
    <Ellipsis
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (!menuIsOpen) openMenu()
        else closeMenu()
      }}
    >
      <img src={EllipsisIcon} alt='' />
      <components.Control {...props} />
    </Ellipsis>
  )

  const MenuListComponent = (
    props: MenuListComponentProps<CardOptions, false, GroupTypeBase<CardOptions>>
  ) => {
    const { children } = props
    return (
      <components.MenuList {...props}>
        <HeaderText>{headerText}</HeaderText>
        {children}
      </components.MenuList>
    )
  }

  const OptionComponent = (props: OptionProps<CardOptions, false, GroupTypeBase<CardOptions>>) => {
    const { children, innerProps } = props
    return (
      <components.Option
        {...props}
        innerProps={{
          ...innerProps,
          onClick: (e) => {
            e.stopPropagation()
            e.preventDefault()
            innerProps.onClick(e)
          }
        }}
      >
        {children}
      </components.Option>
    )
  }

  return (
    <div ref={containerRef}>
      <Select
        ref={ref}
        menuIsOpen={menuIsOpen}
        components={{
          Control: ControlComponent,
          MenuList: MenuListComponent,
          Option: OptionComponent
        }}
        styles={customStyles}
        options={options}
        onChange={onChange}
        isClearable={false}
        controlShouldRenderValue={false}
        isSearchable={false}
      />
    </div>
  )
}

export default Options
