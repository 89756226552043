import axios from 'axios'

import { api, User, UserRole, Language, Customer } from '@console/common/services/api'

export const getCustomer = async (customerId: string): Promise<Customer | null> => {
  try {
    const { data } = await api.get<Customer>(`/v1/customers/${customerId}`)
    return data
  } catch (e: unknown) {
    return null
  }
}

export const createCustomer = async (
  name: string,
  slug: string,
  email: string,
  language: Language = Language.EN,
  waterMarkPosition = 'bottomLeft',
  active = false,
  logoUrl?: string
): Promise<Customer | { error: number } | null> => {
  try {
    const { data: customer } = await api.post<Customer>('/v1/customers', {
      name,
      slug,
      metadata: { email },
      language,
      waterMarkPosition,
      active,
      logoUrl
    })
    return customer
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      return { error: e.response?.status ?? 500 }
    }
    return { error: 500 }
  }
}

export const patchCustomer = async (
  customerId: string,
  customerParams: Partial<Customer>
): Promise<Customer | null> => {
  try {
    const { data: customer } = await api.patch<Customer>(
      `/v1/customers/${customerId}`,
      customerParams
    )
    return customer
  } catch (e) {
    return null
  }
}

export const getUsers = async (): Promise<User[] | null> => {
  try {
    const { data } = await api.get<User[]>('/v1/users')
    return data
  } catch (e) {
    return null
  }
}

export const getUser = async (userId: string): Promise<User | null> => {
  try {
    const { data } = await api.get<User>(`/v1/users/${userId}`)
    return data
  } catch (e) {
    return null
  }
}

export const createUser = async (
  name: string,
  email: string,
  role: UserRole,
  customerId: string,
  language: Language = Language.EN
): Promise<User | null | { error: number }> => {
  try {
    const { data: user } = await api.post<User>('/v1/users', {
      name,
      id: email,
      role,
      customerId,
      language
    })
    return user
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      return { error: e.response?.status ?? 500 }
    }
    return { error: 500 }
  }
}

export const patchUser = async (
  userId: string,
  name?: string,
  role?: UserRole,
  customerId?: string
): Promise<User | null> => {
  try {
    const { data: user } = await api.patch<User>(`/v1/users/${userId}`, {
      name,
      role,
      customerId
    })
    return user
  } catch (e) {
    return null
  }
}

export const deleteUser = async (userId: string): Promise<User | null> => {
  try {
    const { data } = await api.delete<User>(`/v1/users/${userId}`)
    return data
  } catch (e) {
    return null
  }
}

export const inviteAgain = async (userId: string): Promise<User | null> => {
  try {
    const { data } = await api.post<User>(`/v1/users/${userId}/inviteAgain`)
    return data
  } catch (e) {
    return null
  }
}
