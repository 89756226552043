import React, { useState, useEffect, useRef, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@console/common/components/Button'
import Cascade, { SelectOptions } from '@console/common/components/Cascade'
import TextInput, { InputTypes } from '@console/common/components/TextInput'
import { AnalyticsContext, AnalyticsEvent } from '@console/common/contexts/AnalyticsContext'
import { AuthContext } from '@console/common/contexts/AuthContext'
import { ModalContext } from '@console/common/contexts/ModalContext'
import { UserRole, Language, getCustomers } from '@console/common/services/api'

import Header from '../../../components/Header'
import Popup from '../../../components/Popup'
import Spinner from '../../../components/Spinner'
import { createUser } from '../../../services/api'
import { Container, FieldNames, ButtonContainer } from './styles'

const Invite: React.FC = () => {
  const { t } = useTranslation(['settings'])
  const { session } = useContext(AuthContext)
  const { isOpen, openModal } = useContext(ModalContext)
  const { analytics } = useContext(AnalyticsContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [customerOptions, setCustomerOptions] = useState<SelectOptions[]>([])
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [customer, setCustomer] = useState<SelectOptions | null>(null)
  const [access, setAccess] = useState<SelectOptions | null>(null)
  const [validity, setValidity] = useState<boolean>(false)
  const nameRef = useRef<HTMLDivElement>(null)
  const emailRef = useRef<HTMLDivElement>(null)
  const [statusCode, setStatusCode] = useState<number | null>(null)

  const AccessOptions = [
    {
      value: UserRole.ADMIN,
      label: t('users.admin')
    },
    {
      value: UserRole.ARTIST,
      label: t('users.artist')
    },
    {
      value: UserRole.CUSTOMER,
      label: t('users.customer')
    },
    {
      value: UserRole.R2USER,
      label: t('users.r2user')
    },
    {
      value: UserRole.FREELANCER,
      label: t('users.freelancer')
    }
  ]

  useEffect(() => {
    if (session?.role !== UserRole.ADMIN && session?.role !== UserRole.R2USER) {
      setAccess(AccessOptions.filter((a) => a.value === session?.role)[0])
      setCustomer(customerOptions[0])
    }
  }, [customerOptions, session])

  useEffect(() => {
    const nameValidity = (nameRef.current?.children[0] as HTMLInputElement)?.checkValidity()
    const emailValidity = (emailRef.current?.children[0] as HTMLInputElement)?.checkValidity()
    setValidity(nameValidity && emailValidity && !!customer && !!access)
  }, [name, email, customer, access])

  useEffect(() => {
    analytics?.track(AnalyticsEvent.SETTINGS_USERS_INVITE)
  }, [analytics])

  useEffect(() => {
    getCustomers().then((c) => {
      if (c) {
        setCustomerOptions(
          c.map((value) => ({
            value,
            label: value.name
          }))
        )
      }
    })
  }, [])

  const sendInvite = async () => {
    if (!validity) return
    if (isOpen) return
    setLoading(true)
    const user = await createUser(
      name,
      email,
      access?.value,
      customer?.value.id,
      customer?.value.language || Language.EN
    )

    if (user && 'error' in user) {
      setStatusCode(user.error)
    } else {
      setStatusCode(null)
    }
    setLoading(false)
    openModal()
  }

  return (
    <>
      <Popup statusCode={statusCode} message='users.invite' route='/settings/users' />
      <Container>
        <Header returnPage='/settings/users' />
        <FieldNames>{t('users.invite.name')}</FieldNames>
        <div ref={nameRef}>
          <TextInput
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            placeholder={t('users.invite.nameExample')}
            width='487px'
            maxLength={255}
            type={InputTypes.TEXT}
            required
          />
        </div>
        <FieldNames>{t('users.invite.email')}</FieldNames>
        <div ref={emailRef}>
          <TextInput
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder={t('users.invite.emailExample')}
            width='337px'
            maxLength={255}
            type={InputTypes.EMAIL}
            required
          />
        </div>
        <FieldNames>{t('users.customer')}</FieldNames>
        <Cascade
          value={customer}
          options={customerOptions}
          onChange={(option) => setCustomer(option)}
          isSearchable
          isLoading={loading}
          isDisabled={
            !session || (session.role !== UserRole.ADMIN && session.role !== UserRole.R2USER)
          }
        />
        <FieldNames>{t('users.invite.access')}</FieldNames>
        <Cascade
          value={access}
          options={AccessOptions}
          onChange={(option) => setAccess(option)}
          isSearchable={false}
          isDisabled={
            !session || (session.role !== UserRole.ADMIN && session.role !== UserRole.R2USER)
          }
        />
        <ButtonContainer>
          <Button onClick={sendInvite} disabled={!validity}>
            {loading ? (
              <>
                {t('users.invite.sending')}
                <Spinner />
              </>
            ) : (
              t('users.invite.send')
            )}
          </Button>
        </ButtonContainer>
      </Container>
    </>
  )
}

export default Invite
