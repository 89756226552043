import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '@console/common/components/Button'
import PopupBase, { PopupMode } from '@console/common/components/Popup'
import { ModalContext } from '@console/common/contexts/ModalContext'

import { Container, MaintText, ButtonsContainer } from './styles'

const ERROR_MESSAGES: Record<number, string> = {
  400: '.popup.error_invalid_email',
  409: '.popup.error_conflict',
  500: '.popup.error'
}

interface PopupProps {
  statusCode?: number | null
  message: string
  route: string
}

const Popup: React.FC<PopupProps> = ({ statusCode, message, route }: PopupProps) => {
  const { t } = useTranslation(['settings'])
  const history = useHistory()
  const { isOpen, closeModal } = useContext(ModalContext)

  const closeCallback = () => {
    closeModal()
    history.push(route)
  }

  const modalMode = statusCode ? PopupMode.ALERT : PopupMode.CONFIRM
  const modalMessage = statusCode
    ? t(message + ERROR_MESSAGES[statusCode] || `${message}.popup.error`)
    : t(`${message}.popup.success`)

  return (
    <PopupBase mode={modalMode} trigger={isOpen}>
      <Container>
        <MaintText>{modalMessage}</MaintText>
        <div />
        <ButtonsContainer>
          <Button onClick={statusCode ? closeModal : closeCallback}>
            {t('users.invite.popup.ok')}
          </Button>
        </ButtonsContainer>
      </Container>
    </PopupBase>
  )
}

export default Popup
